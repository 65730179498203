.millionSongs {
    padding: 66px 40px;
    background: radial-gradient(78.98% 78.98% at 50% 91.18%, #0e0046 0%, #0e0b26 100%);
    overflow: hidden;
  }
  
  .millionSongs h2,
  .millionSongs h2 span {
    font-size: 4.5rem;
    font-weight: 600;
    line-height: 6.75rem;
  }
  
  .millionSongs h2 {
    padding: 0;
    color: #fff;
  }
  
  .millionSongs h2 span {
    background: linear-gradient(266.65deg, #735fff -0.9%, #533aff 106.67%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  
  .millionSongs h2 + p {
    margin-top: 49px;
    line-height: 4.9rem;
  }
  
  .millionSongs figure {
    margin-top: 100px;
    height: 100%;
    width: 100%;
  }
  
  .actions {
    margin-top: 49px;
  }
  
  .actions button {
    margin-right: 17px;
    padding: 11px 24px;
    background: white;
    border-radius: 84px;
    color: #4329ff;
    font-size: 2rem;
  }
  
  .white {
    color: #fff;
  }
  
  .f20 {
    font-size: 2rem;
  }
  
  .f400 {
    font-weight: 400;
  }
  
  .flex {
    display: flex;
  }
  
  .alignCenter {
    align-items: center;
  }
  
  .underline {
    text-decoration: underline;
  }
  
  @media only screen and (min-width: 1024px) {
    .millionSongs {
      padding-bottom: 66px;
    }
  
    .millionSongs > div {
      max-width: 1476px;
      margin: 0 auto;
      display: flex;
    }
  
    .millionSongs > div > div {
      width: 907px;
      margin-right: 64px;
      padding-top: 124px;
    }
  
    .millionSongs > div > div p {
      font-size: 2.4rem;
      line-height: 4.9rem;
    }
  
    .millionSongs > div > div .actions button {
      margin-right: 31px;
    }
  
    .millionSongs > div > div .actions a {
      font-size: 2rem;
      line-height: 4.9rem;
    }
  
    .millionSongs > div h2,
    .millionSongs > div h2 span {
      font-size: 6.4rem;
      line-height: 9.6rem;
    }
  
    .millionSongs > div figure {
      max-width: 500px;
    }
  }