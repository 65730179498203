/* TopArtistsView.module.css */
.loading{
    text-align: center;
    font-size: 2rem;
}

.topArtistsView {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .topArtistsView h1 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .artistList {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    list-style-type: none;
    padding: 0;
  }
  
  @media (max-width: 1200px) {
    .artistList {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 900px) {
    .artistList {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 600px) {
    .artistList {
      grid-template-columns: 1fr;
    }
  }
  
  .artistItem {
    text-decoration: none;
    color: inherit;
    display: block;
  }
  
  .artistItem a {
    text-decoration: none;
    color: inherit;
    display: block;
  }
  
  .thumbnailContainer {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect ratio for artist images */
  }
  
  .artistImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .artistInfo {
    padding: 10px 0;
  }
  
  .artistName {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 5px 0;
    line-height: 1.2;
    max-height: 2.4em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .artistGenre {
    font-size: 12px;
    color: #606060;
    margin: 0 0 5px 0;
  }
  
  .artistPopularity {
    font-size: 12px;
    color: #606060;
    margin: 0;
  }
  
  .loadMoreButton {
    display: block;
    width: 200px;
    margin: 40px auto 0;
    padding: 12px 24px;
    background-color: #551A8B;
    color: #fff;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .loadMoreButton:hover {
    background-color: #4a1577;
  }
  
  .loading {
    text-align: center;
    font-size: 2rem;
    color: #551A8B;
    margin-top: 50px;
  }