/* Footer.module.css */
@media only screen and (min-width: 0) {
    .mainFooter {
        padding: 63px 30px;
        background: linear-gradient(91.45deg, #5e61c2 0.13%, #20105d 100%);
    }

    .logo{
        text-align: center;
    }
    .mainFooter .general,
    .mainFooter .menuSm {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .mainFooter nav {
        margin-top: 50px;
        gap: 20px;
    }

    .mainFooter nav span,
    .mainFooter nav a {
        color: #fff;
    }

    .mainFooter nav a {
        font-weight: 400;
        padding-right: 15px;
    }

    .mainFooter nav .imgLink {
        width: 100%;
        max-width: 25px;
    }

    .mainFooter nav.options {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: fit-content;
    }

    .mainFooter nav.options a,
    .mainFooter nav.options span {
        font-size: 1.4rem;
        opacity: 0.6;
    }

    .mainFooter nav.options .separator {
        margin: 0 12px;
    }

    .mainFooter nav.options > .separator {
        display: none;
    }

    .mainFooter nav.options div .separator {
        display: inline-block;
    }

    .mainFooter nav.socials {
        max-width: 200px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    footer > figure > img{
        margin: auto;
    }
}

@media only screen and (min-width: 1024px) {
    .mainFooter {
        padding-top: 16px;
    }

    .mainFooter .menuSm {
        max-width: 1161px;
        width: 100%;
        margin: 0 auto;
        flex-direction: row;
        justify-content: space-between;
    }

    .mainFooter .menuSm > nav:first-child {
        display: flex;
        gap: 40px;
    }
    .mainFooter .menuSm > nav > a {
        font-size: 1.6rem;
    }

    .mainFooter .general {
        max-width: 1020px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        gap: 32px;
    }
}
