/* src/components/TagView/TagView.module.css */
.tagView {
    padding: 20px;
}

.artistsGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}

.artistCard {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s;
}

.artistCard:hover {
    transform: scale(1.05);
}

.artistCard img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
}

.loading, .error {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
}