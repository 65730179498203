.shareButtons h2{
    text-align: left!important;
    font-size: 18px;
}
.FlexButtons{
    display: flex;
}

.shareButtons {
    gap: 10px;
    align-items: center;
  }
  
  .FlexButtons > button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 24px;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;
  }
  
  button:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  
  button img {
    width: 24px;
    height: 24px;
  }
  
  
  button.copy img {
    filter: invert(8%) sepia(0%) saturate(0%) hue-rotate(311deg) brightness(96%) contrast(85%);
  }