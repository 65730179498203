.newsletterContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
    text-align: center;
  }
  
  .title {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    text-align: center!important;
  }
  
  .artistName {
    color: #6c5ce7;
    font-size: 36px;
  }
  
  .subtitle {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .newsletterForm {
    display: flex;
    background-color: #f0f0f0;
    border-radius: 25px;
    overflow: hidden;
  }
  
  .emailInput {
    flex-grow: 1;
    padding: 12px 15px;
    border: none;
    font-size: 14px;
    background-color: transparent;
  }
  
  .emailInput::placeholder {
    color: #999;
  }
  
  .subscribeButton {
    background-color: #6c5ce7;
    color: white;
    border: none;
    padding: 12px 20px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .subscribeButton:hover {
    background-color: #5b4bc7;
  }

  @media (max-width: 767.6px) {
  .title {
    font-size: 30px;
    padding: 10px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
    text-align: center !important;
}
  

}