.header {
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 12px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: box-shadow 0.3s ease;
}

.header:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 24px;
}

.navLinks {
  display: flex;
  align-items: center;
  gap: 20px;
}

.navLink {
  text-decoration: none;
  color: #333333;
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  transition: all 0.3s ease;
  padding: 8px 12px;
  border-radius: 4px;
}

.navLink:hover {
  color: #551A8B;
  background-color: rgba(85, 26, 139, 0.1);
}

.userProfileMenu {
  flex-shrink: 0;
  position: relative;
  z-index: 1001;
}

.loginButton {
  background-color: #ffffff;
  color: #551A8B;
  border: 1px solid #551A8B;
  padding: 5px 15px 5px 15px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(85, 26, 139, 0.1);
}

.loginButton:hover {
  background-color: #f8f4ff;
  box-shadow: 0 4px 8px rgba(85, 26, 139, 0.2);
  transform: translateY(-1px);
}

.loginButton img {
  width: 80px;
  height: auto;
  object-fit: contain;
}

.loginButton span {
  font-size: 1.6rem;
}

.buttonIcon {
  font-size: 18px;
}

.mobileMenuButton {
  display: none;
}

.userContainer {
  position: relative;
}

.userButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: transform 0.2s ease;
}

.userButton:hover {
  transform: scale(1.05);
}

.userAvatar,
.userIcon {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.userMenu {
  position: absolute;
  top: calc(100% + 12px);
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 20px;
  min-width: 280px;
  z-index: 1000;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

.userInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.userName {
  font-weight: 600;
  font-size: 18px;
  color: #333333;
}

.userEmail {
  color: #666666;
  font-size: 14px;
  margin-top: 4px;
}

.searchesInfo {
  font-size: 14px;
  color: #666666;
  margin-bottom: 16px;
  padding: 8px 12px;
  background-color: #f5f5f5;
  border-radius: 6px;
}

.logoutButton {
  background: none;
  border: none;
  color: #551A8B;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px 0;
  transition: all 0.3s ease;
  width: 100%;
}

.logoutButton:hover {
  color: #4a1577;
  background-color: rgba(85, 26, 139, 0.1);
  border-radius: 6px;
  padding-left: 10px;
}

.logoutIcon {
  margin-right: 12px;
}

.navButton {
  background: none;
  border: none;
  color: #333333;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.navButton:hover {
  color: #551A8B;
  background-color: rgba(85, 26, 139, 0.1);
}

.navButtonIcon {
  margin-right: 8px;
  font-size: 18px;
}

.mobileMenuLogo{
  display: none;
  visibility: hidden;
}

.mobileMenuContent{
  display: flex;
}

@media (max-width: 1024px) {
  .nav {
    padding: 0 20px;
  }

  .navLink, .navButton {
    font-size: 14px;
    padding: 6px 10px;
  }
  
  .loginButton {
    padding: 6px 12px;
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .mobileMenuLogo{
    display: block;
    visibility: visible;
  }
  .header {
    padding: 10px 0;
  }

  .nav {
    flex-wrap: wrap;
  }

  .mobileMenuButton {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 24px;
    color: #551A8B;
    z-index: 1002;
    order: 1;
    transition: color 0.3s ease;
  }

  .mobileMenuButton:hover {
    color: #4a1577;
  }

  .userProfileMenu {
    order: 2;
  }

  .navLinks {
    display: none;
    width: 100%;
    order: 3;
  }

  .navLinks.mobileMenuOpen {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    z-index: 999;
    justify-content: center;
    align-items: center;
    animation: slideIn 0.3s ease-in-out forwards;
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .mobileMenuContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 20px;
  }

  .mobileMenuOpen .navLink,
  .mobileMenuOpen .navButton {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    color: #333333;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .loginButton {
    width: 100%;
    justify-content: center;
  }

  .userMenu {
    right: -16px;
    width: calc(100vw - 32px);
    max-width: 320px;
  }
}