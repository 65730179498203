.searchBarContainer {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  margin-top: 10%;
}

.searchBar {
  display: flex;
  align-items: center;
}

.inputWrapper {
  position: relative;
  width: 100%;
}

.input {
  width: 100%;
  padding: 12px 50px 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 24px;
  outline: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
}

.input:focus {
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
}

.searchIcon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #9aa0a6;
  font-size: 18px;
}

.clearButton {
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #70757a;
  font-size: 18px;
}

.submitButton {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  background: #f8f9fa;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  padding: 10px;
  color: #4285f4;
  font-size: 10px;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #e8eaed;
}

.submitButton:disabled {
  color: #9aa0a6;
  cursor: not-allowed;
}

.suggestionsList {
  position: absolute;
  display: block;
  top: calc(100% - 24px);
  left: 0;
  right: 0;
  background-color: white;
  border-radius: 0 0 24px 24px;
  box-shadow: 0 4px 6px rgba(32,33,36,0.28);
  list-style-type: none;
  margin: 0;
  padding: 24px 0 0 0; 
  z-index: 998; 
  overflow-y: auto;
  max-height: 300px;
}

.suggestionItem {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.suggestionsListSpan{
   font-size: 1.6rem;
}
.suggestionsListSpan > strong {
  font-size: 16px;
}

.suggestionItem:hover, .suggestionItem.selected {
  background-color: #f1f3f4;
}

.suggestionIcon {
  margin-right: 10px;
  color: #9aa0a6;
}

.errorMessage {
  color: #d93025;
  margin-top: 10px;
  font-size: 14px;
}

span > strong {
  font-weight: 600;
}

.inputWrapper {
  position: relative;
  z-index: 999;
}

@media (max-width: 767px) {
  .searchBarContainer {
    max-width: 100%;
    padding: 10px 10px;
    margin-top: 20%;
  }

  .input {
    font-size: 14px;
    padding: 10px 45px 10px 15px;
  }

  .searchIcon {
    left: 10px;
    font-size: 16px;
  }

  .clearButton {
    right: 45px;
    font-size: 16px;
  }

  .submitButton {
    padding: 8px;
    font-size: 16px;
  }

  .suggestionItem {
    padding: 8px 15px;
  }
}