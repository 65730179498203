.formattedLyrics {
    font-size: 3rem;
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .lyrics {
    display: flex;
    flex-direction: column;
  }
  
  .lyricsLine {
    color: #333;
    margin-bottom: 4px;
    font-weight: 500;
  }
  
  .translatedLine {
    color: #666;
    margin-bottom: 16px;
    font-style: italic;
  }
  
  .desktopLyrics {
    display: none;
  }
  
  .mobileLyrics {
    display: block;
  }
  
  @media (min-width: 768px) {
    .desktopLyrics {
      display: block;
    }

    .formattedLyrics {
        font-size: 2rem;
      }

    .mobileLyrics {
      display: none;
    }
  }

  .formattedLyrics {
    font-size: 16px;
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .lyricsLine {
    color: #333;
    margin-bottom: 4px;
    font-weight: 500;
  }
  
  .translatedLine {
    color: #666;
    margin-bottom: 16px;
    font-style: italic;
  }
  
  @media (max-width: 767px) {
    .formattedLyrics {
      font-size: 14px;
    }
    
    .lyricsLine, .translatedLine {
      white-space: normal;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }