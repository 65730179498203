.albumView {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fdfdfd;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.albumImage {
    width: 300px;
    height: 300px;
    object-fit: cover;
    border-radius: 12px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.albumImage:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.albumInfo {
    margin-left: 40px;
    flex-grow: 1;
}

.albumInfo h1 {
    font-size: 2.8rem;
    margin-bottom: 10px;
    color: #222;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}

.albumInfo h2 {
    font-size: 2.2rem;
    margin-bottom: 20px;
    color: #555;
}

.releaseDate, .stats {
    font-size: 1.4rem;
    color: #777;
    margin-bottom: 20px;
}

.stats {
    display: flex;
    gap: 15px;
}

.stats span {
    background-color: #eaecef;
    padding: 10px 18px;
    border-radius: 18px;
    font-size: 1.4rem;
    transition: background-color 0.3s ease;
}

.stats span:hover {
    background-color: #d4d6d9;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 20px;
}

.tag {
    background-color: #5A5AD1;
    color: #ffffff;
    padding: 8px 15px;
    border-radius: 20px;
    font-size: 1.1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.tag:hover {
    background-color: #48489b;
    transform: translateY(-2px);
}

.content {
    display: flex;
    gap: 30px;
}

.mainContent {
    flex: 3;
}

.tracksCard, .albumDescription, .sidebarSection {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    transition: box-shadow 0.3s ease;
}

.tracksCard:hover, .albumDescription:hover, .sidebarSection:hover {
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.tracksCard h2, .albumDescription h2, .sidebarSection h3 {
    font-size: 2.6rem;
    margin-bottom: 25px;
    color: #333;
    border-bottom: 2px solid #5A5AD1;
    padding-bottom: 15px;
}

.tracksList {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.trackItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 1px solid #eee;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    background-color: #f9fafb;
}

.trackItem:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transform: translateY(-5px);
    background-color: #ffffff;
}

.trackNumber {
    font-weight: bold;
    color: #5A5AD1;
    font-size: 1.8rem;
    margin-bottom: 10px;
}

.trackName {
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 10px;
    color: #eee;
}

.trackDuration {
    color: #eaecef;
    font-size: 1.2rem;
}

.sidebar {
    flex: 1;
}

.sidebarSection ul {
    list-style: none;
    padding: 0;
    text-align: left;
}

.sidebarSection li {
    margin-bottom: 20px;
    font-size: 1.8rem;
    line-height: 1.6;
    transition: transform 0.3s ease;
}

.sidebarSection li:hover {
    transform: translateX(5px);
}

.sidebarSection li strong {
    color: #5A5AD1;
    display: block;
    margin-bottom: 5px;
    font-size: 2rem;
}

.lastfmLink, .readMoreBtn, .viewMoreBtn {
    display: inline-block;
    background-color: #d51007;
    color: #ffffff;
    padding: 12px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    border: none;
    cursor: pointer;
}

.lastfmLink:hover, .readMoreBtn:hover, .viewMoreBtn:hover {
    background-color: #b30e06;
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.readMoreBtn, .viewMoreBtn {
    background-color: #5A5AD1;
    margin-top: 20px;
    font-size: 1.8rem;
}

.readMoreBtn:hover, .viewMoreBtn:hover {
    background-color: #48489b;
}

.loading, .error, .noData {
    font-size: 1.8rem;
    color: #333;
    text-align: center;
    margin: 100px 0;
}

.albumDescription p {
    font-size: 2rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #444;
}

.ulInfo {
    flex-direction: column !important;
}

.tracksCard {
    margin: 20px 0;
}

.tracksList {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.trackItem {
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.trackItem:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.trackBackground {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.overlay {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.trackNumber {
    position: absolute;
    top: 10px;
    left: 10px;
    background: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 15px;
    font-size: 12px;
    z-index: 1000;
}

.trackName {
    font-size: 24px;
    font-weight: bold;
    z-index: 1001;
}

.trackDuration {
    font-size: 16px;
    margin-top: 10px;
}

.trackViewCount {
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 10px;
}

@media (max-width: 1024px) {
    .content {
        flex-direction: column;
    }

    .sidebar {
        order: -1;
    }
}


@media (max-width: 768px) {
    .header {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
    }

    .albumImage {
        width: 250px;
        height: 250px;
        margin-bottom: 20px;
    }

    .albumInfo {
        margin-left: 0;
    }

    .albumInfo h1 {
        font-size: 2.5rem;
    }

    .albumInfo h2 {
        font-size: 2rem;
    }

    .stats {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .tracksList {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    .tags, .stats {
        justify-content: center;
    }

    .tracksCard, .albumDescription, .sidebarSection {
        padding: 20px;
    }

    .trackItem {
        max-width: 100%;
        height: 300px;
    }

    .trackNumber {
        font-size: 14px;
        padding: 4px 8px;
    }

    .trackName {
        font-size: 24px;
    }

    .trackDuration {
        font-size: 14px;
    }

    .trackViewCount {
        font-size: 14px;
    }

    .sidebarSection li {
        font-size: 1.6rem;
    }
}
