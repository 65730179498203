@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f8f8ff;
}

* {
  box-sizing: border-box;
}

  h1, h2, h3 {
    margin-bottom: 1rem;
  }
  
  button {
    cursor: pointer;
  }
  