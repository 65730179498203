.featureSection {
    flex: 1;
    text-align: center;
    padding: 20px;
  }
  
  .title {
    color: #5e35b1;
    font-size: 28px;
    margin-bottom: 15px;
  }
  
  .description {
    color: #666;
    margin-bottom: 30px;
    line-height: 1.6;
  }
  
  .imageWrapper {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .imageWrapper:hover {
    transform: translateY(-5px);
  }
  
  .image {
    max-width: 100%;
    border-radius: 5px;
  }