/* src/components/SearchResults/SearchResults.module.css */
h1{
  font-size: 26px;
  margin-bottom: 1%;
  padding-left: 20px;
}

.resultsContainer {
    background-color: rgba(255, 255, 255, 0.1)!important;
    border-radius: 15px!important;
    padding: 20px 20px 20px 20px!important;
    backdrop-filter: blur(10px)!important;
    margin-top: 20px!important;
  }
  
  
  .resultsTitle {
    color: #ffffff;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .videoContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .videoContainer {
    max-width: 100vw;
}

  
  .lyricsContainer {
    display: flex;
    justify-content: space-between!important;
    max-width: 75%;
    height: auto;
  }
  
  .originalLyrics,
  .translatedLyrics {
    width: 48%;
    background-color: rgba(92, 96, 192, 0.2)!important;
    border-radius: 10px;
    padding: 15px;
  }
  
  .originalLyrics h3,
  .translatedLyrics h3 {
    margin-bottom: 10px;
    font-size: 2.5rem;
    color: #222;
  }

  .originalLyrics div,
  .translatedLyrics div {
    font-size: 1.9rem;
    color: #222;
    padding: 2%;
    line-height: 1.8em;
    word-spacing: 4px;
    
  }
  
  .originalLyrics pre,
  .translatedLyrics pre {
    color: #f0f0f0;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 2.5rem;
  }

  h2{
    text-align: center;
    font-size: 2.4rem;
    font-weight: 600;
  }

  .originalLyrics,
  .translatedLyrics > div{
    overflow-wrap: anywhere;
  }

  .searchResults {
    padding: 20px;
    max-width: 1200px;
    margin: 50px;
  }
  .searchResults > p{
    font-size: 18px;
  }
  
  .twoColumnSection {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .videoColumn, .bioColumn {
    flex: 1 1 300px;
  }
  
  .meaningSection, .lyricsSection {
    margin-top: 20px;
  }
  
  button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
  }
  
  .searchResults {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .twoColumnSection {
    display: flex;
    gap: 48px;
    margin-bottom: 5%;
  }
  
  .videoColumn, .bioColumn {
    flex: 1;
  }
  
  .lyricsContainer {
    margin-top: 20px;
  }
  
  .highlighted {
    background-color: yellow;
  }
  
  .searchResults {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .resultHeader {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .resultHeader h1 {
    flex: 1;
    margin: 0;
  }
  
  .artistImage {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 20px;
  }
  
  .artistInfo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  
  .mainInfo, .additionalInfo, .topTracks, .topAlbums {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
  }
  
  .similarArtists, .tags {
    margin-bottom: 20px;
  }
  
  .playcount {
    font-size: 0.8em;
    color: #666;
  }
  
  .twoColumnSection {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .videoColumn, .bioColumn {
    flex: 1;
  }
  
  .videoPlayer {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  
  .songMeaning, .lyrics {
    margin-bottom: 20px;
  }
  
  .lyricsContainer {
    white-space: pre-wrap;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
  }
  
  .highlighted {
    background-color: yellow;
  }
  
  .joinHeader {
    text-align: center;
    margin: 40px 0;
    font-size: 24px;
    color: #333;
  }
  
  .otherResults {
    margin-top: 40px;
  }
  
  .resultGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .otherResult {
    background-color: #f8f8f8;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
  }
  
  .thumbnailImage {
    width: 100%;
    height: 750px;
    object-fit: cover;
    border-radius: 8px;
    margin-top: 10px;
  }
  
  .noResults {
    text-align: center;
    font-size: 24px;
    color: #666;
    margin-top: 50px;
  }

  .artistInfo {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .mainInfo, .additionalInfo, .topTracks, .topAlbums {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
  }
  
  .additionalInfo {
    display: flex;
    gap: 20px;
  }
  
  .similarArtists, .tags {
    flex: 1;
  }
  
  .similarArtists ul, .tags ul {
    list-style-type: none;
    padding: 0;
  }
  
  .similarArtists li, .tags li {
    background-color: #e0e0e0;
    margin: 5px 0;
    padding: 5px 10px;
    border-radius: 15px;
    display: inline-block;
    margin-right: 5px;
  }
  
  .topTracks ol, .topAlbums ol {
    padding-left: 20px;
  }
  
  .topTracks li, .topAlbums li {
    margin-bottom: 10px;
  }
  
  .playcount {
    font-size: 0.8em;
    color: #666;
  }
  
  .biography {
    margin-top: 20px;
  }
  
  .biography p {
    max-height: 200px;
    overflow-y: auto;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .songInfo {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .songInfo p {
    margin: 10px 0;
  }
  
  .lyricsContainer {
    white-space: pre-wrap;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    max-height: 400px;
    overflow-y: auto;
  }

  .searchResults {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .resultsList {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .resultItem {
    display: flex;
    gap: 20px;
  }
  
  .thumbnail img {
    width: 246px;
    height: 138px;
    object-fit: cover;
    border-radius: 12px;
  }
  
  .resultInfo {
    flex: 1;
  }
  
  .resultInfo h3 {
    margin: 0 0 8px 0;
    font-size: 18px;
  }
  
  .resultInfo a {
    color: #0a0a0a;
    text-decoration: none;
  }
  
  .artist {
    color: #606060;
    font-size: 14px;
    margin: 0 0 4px 0;
  }
  
  .stats {
    color: #606060;
    font-size: 14px;
    margin: 0 0 8px 0;
  }
  
  .description {
    color: #606060;
    font-size: 14px;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  .resultsList {
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .resultCard {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
  }
  
  .resultCard:hover {
    transform: translateY(-5px);
  }
  
  .thumbnail {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .cardContent {
    padding: 10px;
    text-align: center;
  }
  
  .cardContent h3 {
    margin: 0 0 5px 0;
    font-size: 1.8rem;
  }
  
  .cardContent p {
    margin: 0 0 10px 0;
    font-size: 1.4rem;
    color: #666;
  }
  
  .viewButton {
    background-color: #1db954;
    color: white;
    border: none;
    font-size: 1.2rem;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .viewButton:hover {
    background-color: #1ed760;
  }
  
  .noResults {
    text-align: center;
    font-size: 1.2em;
    color: #666;
    margin-top: 20px;
  }
  
  .searchResultsContainer {
    padding: 20px;
  }
  
  .resultsList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .resultCard {
    width: 200px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: transform 0.2s;
    cursor: pointer;
  }
  
  .resultCard:hover {
    transform: scale(1.05);
  }
  
  .thumbnail {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .cardContent {
    padding: 10px;
  }
  
  .icon {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  h3 {
    margin: 0 0 5px 0;
    font-size: 16px;
  }
  
  p {
    margin: 0;
    font-size: 14px;
    color: #666;
  }
  
  .alternateLabel {
    font-size: 12px;
    color: #007bff;
    margin-top: 5px;
  }
  
  .viewButton {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .noResults {
    text-align: center;
    font-size: 18px;
    color: #666;
    margin-top: 20px;
  }
  
  .searchResultsContainer {
    margin: auto;
  }
  
  .resultsList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex-direction: row;
  }
  
  .resultCard {
    width: 200px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .resultCard:hover {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transform: translateY(-5px);
  }
  
  .thumbnail {
    width: 100%;
    height: 200px;
    object-fit: contain;
  }
  
  .cardContent {
    padding: 10px;
  }
  
  .icon {
    margin-right: 5px;
  }
  
  .viewButton {
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .suggestionsList {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .suggestionItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 10px;
    background-color: #f0f0f0;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .suggestionItem:hover {
    background-color: #e0e0e0;
  }
  
  .suggestionIcon {
    margin-right: 5px;
  }
  
  .noResults {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  
  @media (max-width: 767.6px) {
    .twoColumnSection {
      flex-direction: column;
    }

    .originalLyrics div,
  .translatedLyrics div {
    font-size: 2rem;
    color: #222;
    text-align: left;
    padding: 2%;
    line-height: 1.5em;
    word-spacing: 2px;
    }
  }
