/* MultipleSongsView.module.css */
.multipleSongsView {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .resultsTitle {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .resultGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
  }
  
  @media (max-width: 1200px) {
    .resultGrid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 900px) {
    .resultGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 600px) {
    .resultGrid {
      grid-template-columns: 1fr;
    }
  }
  
  .resultItem {
    text-decoration: none;
    color: inherit;
    display: block;
  }
  
  .thumbnailContainer {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
  }
  
  .thumbnailImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
  
  .duration {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 2px 4px;
    font-size: 12px;
    border-radius: 2px;
  }
  
  .songInfo {
    padding: 10px 0;
  }
  
  .songTitle {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 5px 0;
    line-height: 1.2;
    max-height: 2.4em;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .artistName {
    font-size: 12px;
    color: #606060;
    margin: 0 0 5px 0;
  }
  
  .viewCount {
    font-size: 12px;
    color: #606060;
    margin: 0;
  }