.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
}

@keyframes modalFadeIn {
  from { opacity: 0; transform: translateY(-20px); }
  to { opacity: 1; transform: translateY(0); }
}

.modalHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.logo {
  height: 40px;
}

.modalTitle {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
  color: #202124;
}

.modalSubtitle {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 24px;
  text-align: center;
  color: #5f6368;
}

.loginButtons {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.loginButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
  background-color: white;
  color: #3c4043;
}

.loginButton:hover {
  background-color: #f7f8f8;
  box-shadow: 0 1px 2px 0 rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15);
}

.loginButton > span {
  font-size: 18px;
}
.googleButton {
  color: #3c4043;
}

.googleIcon {
  background-image: url('https://developers.google.com/identity/images/g-logo.png');
  background-size: 18px;
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.spotifyButton {
  background-color: #1DB954;
  color: white;
}

.spotifyButton:hover {
  background-color: #1ed760;
}

.buttonIcon {
  margin-right: 8px;
  font-size: 18px;
}

.divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 20px 0;
}

.divider::before,
.divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #dadce0;
}

.divider span {
  padding: 0 10px;
  color: #5f6368;
  font-size: 14px;
}

.subscribeForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.emailInput {
  padding: 10px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 14px;
}

.subscribeButton {
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.subscribeButton:hover {
  background-color: #1765cc;
}

.privacyText {
  font-size: 12px;
  line-height: 1.4;
  margin-top: 24px;
  color: #5f6368;
  text-align: center;
}

.subscribeForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.emailInput {
  padding: 10px;
  border: 1px solid #dadce0;
  border-radius: 4px;
  font-size: 14px;
}

.subscribeButton {
  background-color: #1a73e8;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.subscribeButton:hover {
  background-color: #1765cc;
}

.subscribeButton:disabled {
  background-color: #9aa0a6;
  cursor: not-allowed;
}

.errorMessage {
  color: #d93025;
  font-size: 12px;
  margin-top: -8px;
}

.successMessage {
  color: #188038;
  font-size: 16px;
  text-align: center;
  margin: 16px 0;
}

.privacyText {
  font-size: 12px;
  line-height: 1.4;
  margin-top: 24px;
  color: #5f6368;
  text-align: center;
}