.artistView {
    max-width: 90%;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #333;
    background-color: #f9f9f9;
}

.header {
    text-align: center;
    margin-bottom: 30px;
    background-color: #755FFF;
    color: white;
    padding: 40px 20px;
    border-radius: 10px;
}

.header h1 {
    font-size: 3.5em;
    margin-bottom: 10px;
    text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
}

.genre {
    font-size: 1.4em;
    opacity: 0.8;
}

.mainContent {
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
}

.leftColumn, .rightColumn {
    flex: 1;
    min-width: 300px;
}

.card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
}

.card:hover {
    transform: translateY(-5px);
}

.card h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #755FFF;
    border-bottom: 2px solid #755FFF;
    padding-bottom: 10px;
}

.showMoreBtn {
    background: none;
    border: none;
    color: #755FFF;
    cursor: pointer;
    font-size: 1em;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.showMoreBtn:hover {
    text-decoration: underline;
}

.showMoreBtn svg {
    margin-left: 5px;
}

.statsCard, .biography, .similarArtists, .tags, .spotify {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
}

.similarArtists > ul {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.similarArtists > ul > li {
    background-color: #e9e9e9;
    padding: 8px 15px;
    border-radius: 20px;
    font-size: 1em;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.statsCard:hover, .biography:hover, .similarArtists:hover, .tags:hover, .spotify:hover {
    transform: translateY(-5px);
}

.statsCard {
    display: flex;
    justify-content: space-around;
    background-color: #f0f0f0;
}

.stat {
    text-align: center;
    display: flex;
    align-items: center;
}

.icon {
    font-size: 2.5em;
    margin-right: 15px;
    color: #755FFF;
}

.stat h3 {
    font-size: 1.1em;
    margin: 0;
    color: #666;
}

.stat p {
    font-size: 1.8em;
    font-weight: bold;
    margin: 5px 0 0;
    color: #755FFF;
}

.tagCloud {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.tag {
    background-color: #e9e9e9;
    padding: 8px 15px;
    border-radius: 20px;
    font-size: 1em;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.tag:hover {
    background-color: #755FFF;
    color: white;
}

.tagIcon {
    margin-right: 8px;
    font-size: 0.9em;
}

.loading, .error, .noData {
    text-align: center;
    padding: 50px;
    font-size: 1.4em;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.error {
    color: #d32f2f;
}

.topTracksList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 20px;
    list-style: none;
    padding: 0;
}

.topTrackItem {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    height: 100%;
}

.topTrackItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.thumbnailContainer {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    overflow: hidden;
}

.thumbnailImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.playIcon svg {
    color: white;
    font-size: 20px;
}

.topTrackItem:hover .playIcon {
    opacity: 1;
}

.songInfo {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.songTitle {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.artistName {
    font-size: 12px;
    color: #606060;
    margin-bottom: 4px;
}

.viewCount {
    font-size: 12px;
    color: #606060;
    margin-top: auto;
}

.topAlbumsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 20px;
    list-style: none;
    padding: 0;
  }
  
  .topAlbumItem {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    height: 100%;
  }
  
  .topAlbumItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  }
  
  .albumInfo {
    padding: 10px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  
  .albumTitle {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .albumPlaycount {
    font-size: 12px;
    color: #606060;
    margin-top: auto;
  }

  .similarArtistsList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    list-style: none;
    padding: 0;
}

.similarArtistItem {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    height: 100%;
}

.similarArtistItem:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.similarArtistItem .thumbnailContainer {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    overflow: hidden;
}

.similarArtistItem .thumbnailImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.similarArtistItem .artistInfo {
    padding: 10px;
    text-align: center;
}

.similarArtistItem .artistName {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


@media (max-width: 767.6px) {
    .topTracksList {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .mainContent {
        flex-direction: column;
    }
    
    .leftColumn, .rightColumn {
        flex: 1 1 100%;
    }

    .header h1 {
        font-size: 2.5em;
    }

    .genre {
        font-size: 1.2em;
    }

    .statsCard {
        flex-direction: column;
        align-items: center;
    }

    .stat {
        margin-bottom: 20px;
    }
}