:root {
    --primary-dark: #4D4CA8;
    --primary-light: #D269FF;
    --secondary-dark: #3A3982;
    --secondary-light: #E194FF;
    --accent: #7E57C2;
    --text-dark: #333333;
    --text-light: #FFFFFF;
    --background-light: #F5F5F5;
    --background-dark: #2C2B5B;
    --success: #4CAF50;
    --error: #F44336;
    --shadow-color: rgba(77, 76, 168, 0.2);
}

.songView {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    padding: 16px;
    font-family: 'Roboto', Arial, sans-serif;
    color: #333;
    background-color: #f9f9f9;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background: linear-gradient(to right, #551A8b, #191414);
    color: white;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
}

.coverArt {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
}

.coverArt img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.songInfo {
    width: 100%;
}

.songInfo h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    word-wrap: break-word;
}

.songInfo h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
    cursor: pointer;
    color: #1DB954;
}

.songMeta {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
    font-size: 0.9rem;
}

.actions {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.playButton, .lyricsPlayButton {
    padding: 8px 16px;
    background-color: #1DB954;
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.3s ease;
}

.playButton:hover, .lyricsPlayButton:hover {
    background-color: #1ed760;
    transform: scale(1.05);
}



.lyricsPlayButton {
    padding: 8px 16px;
    font-size: 0.9rem;
}

.iconButton {
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    font-size: 1.2rem;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.iconButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: scale(1.1);
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.mainColumn {
    flex: 1;
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}

.lyricsSection {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}


.lyricsTitleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lyricsTitle {
    font-size: 1.5rem;
    margin: 0;
    color: #1DB954;
}

.lyricsHeader {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
}

.lyricsHeader h3 {
    font-size: 3.5rem;
    margin: 0;
    color: #1DB954;
}

.lyricsContent {
    font-size: 2rem;
    line-height: 1.6;
    margin-top: 40px;

}

.lyricsContent > div > div > div{
    font-size: 4rem;
    line-height: 1.6;
    text-align: center;
}

.lyricsContent > div > div > div:first-child {
    color: #7D57C2;
    margin-bottom: 15px;
   }

.lyricsContent > div > div > div:nth-child(odd):not(:first-child) {
   color: #7D57C2;
   margin-bottom: 15px;
   margin-top: 15px;
  }

.lyrics {
    white-space: pre-wrap;
    background-color: #f9f9f9;
    padding: 15px;
    font-size: 2rem;
    font-weight: 600;
    border-radius: 8px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebarSection {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.sidebarSection h3 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #1DB954;
    border-bottom: 2px solid #1DB954;
    padding-bottom: 5px;
}

.sidebarSection ul {
    list-style-type: none;
    padding: 0;
    flex-direction: column;
}

.sidebarSection li {
    padding: 12px 10px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-size: 1.8rem!important;
}

.sidebarSection li:hover {
    background-color: #f0f0f0;
    padding-left: 15px;
}

.loading, .error, .noData {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    font-size: 1.1rem;
    color: #606060;
    text-align: center;
    padding: 20px;
}

.translationToggle {
    display: flex;
    justify-content: center;
    border-radius: 50px;
    padding: 4px;
    gap: 15px;
}

.translationButton {
    padding: 8px 16px;
    background-color: #745EFE;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.3s ease;
}

.translationButton.active {
    background-color: #1DB954;
    color: white;
}

.songView {
    color: var(--text-dark);
    background-color: var(--background-light);
}

.header {
    background: linear-gradient(to right, var(--primary-dark), var(--primary-light));
    color: var(--text-light);
}

.coverArt img {
    box-shadow: 0 4px 10px var(--shadow-color);
}

.songInfo h2 {
    color: var(--secondary-light);
}

.playButton, .lyricsPlayButton {
    background-color: var(--accent);
    color: var(--text-light);
}

.playButton:hover, .lyricsPlayButton:hover {
    background-color: var(--secondary-light);
}

.iconButton {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--text-light);
}

.iconButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.videoWrapper {
    box-shadow: 0 4px 10px var(--shadow-color);
}

.lyricsSection, .sidebarSection {
    background-color: var(--text-light);
    box-shadow: 0 4px 10px var(--shadow-color);
}

.lyricsTitle {
    color: var(--primary-dark);
}

.lyrics {
    background-color: var(--background-light);
    box-shadow: inset 0 2px 4px var(--shadow-color);
}

.sidebarSection h3 {
    color: var(--primary-dark);
    border-bottom: 2px solid var(--primary-light);
}

.sidebarSection li:hover {
    background-color: var(--background-light);
}

.translationButton {
    background-color: var(--secondary-dark);
    color: var(--text-light);
}

.translationButton.active {
    background-color: var(--primary-light);
}

.loading, .error, .noData {
    color: var(--text-dark);
}

.sticky {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    background-color: var(--primary-dark);
    border-radius: 50px;
    padding: 10px 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.scrollingPlayButton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #551A8B;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    z-index: 1000;
}

.scrollingPlayButton:hover {
    background-color: #4a1577;
    transform: scale(1.1);
}

.lyricsLoading, .similarTracksLoading {
    padding: 20px;
    text-align: center;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin: 10px 0;
}

.lyricsLoading::after, .similarTracksLoading::after {
    content: '...';
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% { content: '.'; }
    33% { content: '..'; }
    66% { content: '...'; }
}

@media (max-width: 768.6px) {
    .scrollingPlayButton {
        bottom: 10px;
        right: 10px;
        width: 50px;
        height: 50px;
        font-size: 20px;
    }

    .lyricsContent > div > div > div{
        font-size: 2.5rem;
    }

    .H1Span{
        font-size: 2rem!important;
    }

    .songMeta > span{
        font-size: 1.5rem;
    }
}

@media (min-width: 768.6px) {
    .header {
        flex-direction: row;
        text-align: left;
        align-items: flex-start;
    }

    .coverArt {
        margin-right: 30px;
        margin-bottom: 0;
    }

    .songInfo {
        flex: 1;
    }

    .songInfo h1 {
        font-size: 2.5rem;
        cursor: pointer;
    }


    .H1Span{
        font-size: 3rem!important;
    }

    .H1Span:hover {
        font-size: 3.3rem;
        transition-duration: 0.5ms;
        cursor: pointer;
    }

    .songInfo h2 {
        font-size: 1.8rem;
    }

    .songMeta {
        justify-content: flex-start;
        padding-left: 20px;
    }

    .songMeta > span {
        font-size: 16px;
    }

    .actions {
        justify-content: flex-start;
        padding-left: 20px;
    }

    .content {
        flex-direction: row;
    }

    .mainColumn {
        flex: 2;
        margin-right: 20px;
    }

    .sidebar {
        flex: 1;
    }

    .lyricsTitleRow {
        gap: 15px;
    }
}

@media (min-width: 1200px) {
    .songView {
        padding: 30px;
    }

    .header {
        padding: 30px;
    }

    .coverArt {
        width: 250px;
        height: 250px;
    }

    .songInfo h1 {
        font-size: 3rem;
    }

    .H1Span{
        font-size: 3rem;
    }

    .songInfo h2 {
        font-size: 2rem;
    }
}