.loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .musicSheet {
    width: 80%;
    height: 300px;
    background-color: #fffaf0;
    background-image: 
      linear-gradient(#d1d1d1 1px, transparent 1px),
      linear-gradient(90deg, #d1d1d1 1px, transparent 1px);
    background-size: 24px 24px;
    border: .5px solid #8B4513; 
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .musicSheet::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-image: 
      linear-gradient(#8B4513 1px, transparent 1px);
    background-size: 24px 12px;
    opacity: 0.7;
  }
  
  .loadingContainer {
    width: 90%;
    height: 180px;
    position: relative;
  }
  
  .musicNote {
    position: absolute;
    width: 48px;
    height: 48px;
    opacity: 0;
    color: #8B5CF6;
    animation: moveNote 4s linear infinite, bounce 0.5s ease-in-out infinite alternate;
  }
  
  .musicNote:nth-child(1) { animation-delay: 0s, 0.1s; }
  .musicNote:nth-child(2) { animation-delay: 0.8s, 0.2s; }
  .musicNote:nth-child(3) { animation-delay: 1.6s, 0.3s; }
  .musicNote:nth-child(4) { animation-delay: 2.4s, 0.4s; }
  .musicNote:nth-child(5) { animation-delay: 3.2s, 0.5s; }
  
  @keyframes moveNote {
    0% {
      left: -50px;
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      left: calc(100% + 50px);
      opacity: 0;
    }
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-40px); 
    }
  }