* {
  font-family: Roboto, -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;
}

body,
html {
  margin: 0;
  padding: 0;
  font-size: 62.5%;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background: linear-gradient(183.7deg, #e3e6ff .16%, #f4f6ff 31.87%, rgba(244, 246, 255, .52) 60.56%, rgba(229, 202, 255, .3) 100%);
}

body {
  min-width: 100px;
  min-height: 100%;
  font-family: Roboto, -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  line-height: 1.5;
  font-size: 14px;
}

.content {
  max-width: 100vw;
  font-family: 'Roboto', sans-serif;
}

.main {
  display: flex;
  flex-direction: column;
}

.home-page {
  display: flex;
  flex-direction: column;
  gap: 56px
}

.home-page .logo {
  margin-top: 20px
}

.home-page .recently-added {
  margin: 30px 0
}

.features {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.features h2 {
  margin-bottom: 24px;
  font-size: 4rem;
  font-weight: 600;
  text-align: center!important;
  background: #551A8b;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.features h2+p {
  font-size: 1.8rem;
  text-align: center;
  font-weight: 400;
}

.app {
  min-height: 100vh;
  background: linear-gradient(183.7deg, #e3e6ff .16%, #f4f6ff 31.87%, rgba(244, 246, 255, .52) 60.56%, rgba(229, 202, 255, .3) 100%);
  overflow: hidden;
}

section,
summary {
  display: block;
}

.millionsOfSongs {
  padding: 66px 40px;
  overflow: hidden;
  width: 100vw;
  background: radial-gradient(78.98% 78.98% at 50% 91.18%, #0e0046 0%, #0e0b26 100%);
}

.millionsOfSongs>div>div {
  padding-top: 124px;
  width: 907px;
}

.millionsOfSongs>div figure {
  max-width: 500px;
}

.millionsOfSongs figure {
  margin-top: 100px;
  height: 100%;
  width: 100%;
}

.millionsOfSongs>div h2,
.millionsOfSongs>div h2 span {
  font-size: 7.4rem;
  text-align: left;
  line-height: 7.6rem;
  padding: 0;
}

.millionsOfSongs>div>div p {
  font-size: 2.8rem;
  line-height: 4.9rem;
}

.millionsOfSongs>div>div>div {
  margin-top: 49px;
}

.millionsOfSongs>div>div>div button {
  margin-right: 31px;
  padding: 11px 24px;
  background: white;
  border-radius: 84px;
  color: #4329ff;
  font-size: 2.3rem;
}

.millionsOfSongs>div>div>div a {
  font-size: 2.3rem;
  line-height: 4.9rem;
  color: #eee;
}

.millionsOfSongs>div>div button {
  margin-right: 17px;
  padding: 11px 24px;
  background: white;
  border-radius: 84px;
  color: #4329ff;
  font-size: 2rem;
}

.millionsOfSongs>div>div p {
  font-size: 2.4rem;
  line-height: 4.9rem;
  color: #fff;
}

.millionsOfSongs h2 {
  padding: 0;
  color: #fff;
}

.millionsOfSongs h2+p {
  margin-top: 49px;
  line-height: 4.9rem;
}

.millionsOfSongs h2 span {
  background: linear-gradient(266.65deg, #735fff -.9%, #533aff 106.67%);
  background-clip: text;
  font-weight: 600;
  -webkit-text-fill-color: transparent;
}

@media only screen and (min-width: 1024px) {
  .millionsOfSongs>div {
    max-width: 1476px;
    margin: 0 auto;
    display: flex;
  }

  .millionsOfSongs {
    padding-bottom: 66px;
  }

}


@media (max-width: 768.7px) {
  .features {
    flex-direction: column;
  }

  .millionsOfSongs {
    padding: 20px;
  }

  .millionsOfSongs>div>div {
    padding-top: 124px;
    width: 100%;
  }
  .millionsOfSongs>div h2,
  .millionsOfSongs>div h2 span {
  font-size: 5.4rem;
  text-align: center;
  line-height: 5.6rem;
  padding: 0;
  }
  .millionsOfSongs>div>div p {
    font-size: 2.4rem;
    text-align: center;
    line-height: 4.9rem;
    color: #fff;
  }
  .millionsOfSongs > div > div > div a {
    font-size: 2rem;
    line-height: 4.9rem;
    color: #eee;
  }
}


@media (min-width: 769px) {
  .features {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 0) {
  .main-footer {
    padding: 63px 30px;
    background: linear-gradient(91.45deg, #5e61c2 .13%, #20105d 100%)
  }

  .main-footer .general,
  .main-footer .menu-sm {
    display: flex;
    flex-direction: column;
    align-items: center
  }

  .main-footer nav {
    margin-top: 50px;
    gap: 20px
  }

  .main-footer nav span,
  .main-footer nav a {
    color: #fff
  }

  .main-footer nav a {
    font-weight: 400
  }

  .main-footer nav .img-link {
    width: 100%;
    max-width: 25px
  }

  .main-footer nav.options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: fit-content
  }

  .main-footer nav.options a,
  .main-footer nav.options span {
    font-size: 1.4rem;
    opacity: .6
  }

  .main-footer nav.options .separator {
    margin: 0 12px
  }

  .main-footer nav.options>.separator {
    display: none
  }

  .main-footer nav.options div .separator {
    display: inline-block
  }

  .main-footer nav.socials {
    max-width: 200px;
    width: 100%;
    display: flex;
    justify-content: space-between
  }
}

@media only screen and (min-width: 1024px) {
  .main-footer {
    padding-top: 16px
  }

  .main-footer>figure {
    display: none
  }

  .main-footer .menu-sm {
    max-width: 1161px;
    width: 100%;
    margin: 0 auto;
    flex-direction: row;
    justify-content: space-between
  }

  .main-footer .menu-sm>nav:first-child {
    gap: 40px;

  }

  .main-footer .general {
    max-width: 1020px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    gap: 32px
  }
}

@media only screen and (min-width: 0) {
  .million-songs {
    padding: 66px 40px;
    background: radial-gradient(78.98% 78.98% at 50% 91.18%, #0e0046 0%, #0e0b26 100%);
    overflow: hidden
  }

  .million-songs h2,
  .million-songs h2 span {
    font-size: 4.5rem;
    font-weight: 600;
    line-height: 6.75rem
  }

  .million-songs h2 {
    padding: 0;
    color: #fff
  }

  .million-songs h2 span {
    background: linear-gradient(266.65deg, #735fff -.9%, #533aff 106.67%);
    background-clip: text;
    -webkit-text-fill-color: transparent
  }

  .million-songs h2+p {
    margin-top: 49px;
    line-height: 4.9rem
  }

  .million-songs figure {
    margin-top: 100px;
    height: 100%;
    width: 100%
  }

  .million-songs .actions {
    margin-top: 49px
  }

  .million-songs .actions button {
    margin-right: 17px;
    padding: 11px 24px;
    background: white;
    border-radius: 84px;
    color: #4329ff;
    font-size: 2rem
  }
}

@media only screen and (min-width: 1024px) {
  .million-songs {
    padding-bottom: 66px;
  }

  .million-songs>div {
    max-width: 1476px;
    margin: 0 auto;
    display: flex
  }

  .million-songs>div>div {
    width: 907px;
    margin-right: 64px;
    padding-top: 124px
  }

  .million-songs>div>div p {
    font-size: 2.4rem;
    line-height: 4.9rem
  }

  .million-songs>div>div .actions button {
    margin-right: 31px
  }

  .million-songs>div>div .actions a {
    font-size: 2rem;
    line-height: 4.9rem
  }

  .million-songs>div h2,
  .million-songs>div h2 span {
    font-size: 6.4rem;
    line-height: 9.6rem
  }

  .million-songs>div figure {
    max-width: 500px
  }
}

@media only screen and (min-width: 0) {
  .main-layout {
    padding-top: 20px
  }

  .main-layout main {
    padding: 50px 30px
  }
}

@media only screen and (min-width: 1024px) {
  .main-layout main {
    padding: 50px 70px
  }
}

@media only screen and (min-width: 0) {
  .search-bar {
    width: 100%;
    max-width: 334px;
    margin: 0 auto;
    display: block;
    position: relative
  }

  .search-bar__icon--search,
  .search-bar__button--clear {
    position: absolute
  }

  .search-bar__icon--search path,
  .search-bar__button--clear path {
    color: gray
  }

  .search-bar__icon--search {
    left: 20px;
    top: 50%;
    transform: translateY(-50%)
  }

  .search-bar__button--clear {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    height: fit-content;
    padding: 0;
    right: 14px;
    top: 50%;
    transform: translateY(-50%)
  }

  .search-bar input {
    width: 100%;
    border: none;
    border-radius: 30px;
    background-color: #fff;
    padding: 10px 20px 10px 70px;
    box-shadow: 0 4px 4px #00000040
  }

  .search-bar input:focus-visible~svg path,
  .search-bar input:focus-visible~button svg path {
    color: #000
  }
}

@media only screen and (min-width: 1024px) {
  .search-bar {
    max-width: 462px
  }
}