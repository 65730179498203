li{
  font-family: Roboto, -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
}
li > a{
    
    font-weight: 300;
    padding-left: 5px;
    font-family: Roboto, -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;

}

a{
  font-size: 14px;
  color: #551A8b;
  font-weight: 300;
}

.breadcrumbs > span{
  font-size: 16px;
    font-weight: 600;
    padding-right: 5px;
    font-family: Roboto, -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.breadcrumbs {
    margin: auto;
    padding-bottom: 3%;
  }
  
ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }

