.downloadSection {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    padding-bottom: 3%;
  }
  
  .appStore, .googlePlay {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    font-size: 14px;
    transition: transform 0.2s ease;
  }
  
  .f20 {
    font-size: 2rem;
  }

  .f40{
    font-weight: 400;
  }
  .textcenter{
    text-align: center;
  }

  .appStore:hover, .googlePlay:hover {
    transform: translateY(-2px);
  }
  
  .appStore {
    background-color: #000;
  }
  
  .googlePlay {
    background-color: #689f38;
  }
  
  .appStore svg, .googlePlay svg {
    font-size: 24px;
    margin-right: 10px;
  }

  .store-options figure {
    gap: 15px;
    max-width: 440px;
    margin: 0 auto;
}

.flex-center {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  -webkit-justify-content: center;
  align-content: center;
  -webkit-align-content: center;
  align-items: center;
  -webkit-align-items: center;
}

.flex {
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
}
figure{
    display: flex;
    align-items: center;
    margin: auto;
    gap: 20px;
}